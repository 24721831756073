<!--
  封装了请求的下拉框
  @author GongLiHai
-->
<template>
  <a-select :mode="multi ? 'multiple' : 'default'" :value="thisValue" @change="change" :placeholder="placeholder"
    allowClear style="width: 100%" option-label-prop="label" :filterOption="filterOption" showSearch>
    <a-select-option v-for="(option, index) in thisOptions" :key="index" :value="option[valueFieldName]"
      :label="option[labelFieldName]" :optionData="option">
      <slot :option="option" :index="index" :options="thisOptions">{{ option[labelFieldName] }}</slot>
    </a-select-option>
  </a-select>
</template>

<script>
import request from "@/utils/request.js";
export default {
  name: 'ApiSelect',
  props: {
    api: String,          // 选项数据来源请求地址, GET
    nameMapping: String,  // 选项显示名称字段映射
    valueMapping: String, // 选项值字段映射
    value: null,        // 本组件绑定value
    placeholder: String,  // 提示文字
    option: Array,       // 静态选项
    // 多选
    multi: {
      type: Boolean,
      default: false
    },
    // 多选值类型, array 数组, string 逗号分隔id字符串
    multiValueType: {
      type: String,
      default: "array"
    },
    // 查询的属性字段名
    searchKeys: {
      type: Array,
      default() {
        return [];
      }
    },
  },
  data() {
    return {
      thisOptions: [], // 选项数据
    }
  },
  created() {
    this.requestOptions();
  },
  methods: {
    /**
     * 请求获取选项数据
     */
    requestOptions() {
      // 静态写死的 option 选项
      if (this.option) {
        this.thisOptions = this.option;
        return;
      }
      // api, 从接口中获取
      if (this.api) {
        request({
          url: this.api,
          method: 'get',
        }).then(r => {
          this.thisOptions = r.data;
        })
        return;
      }
    },
    /**
     * 下拉框值更新, 通知上层更新
     * @param {*} value 新值
     */
    change(value) {
      // 多选, 值类型为 string, 添加 逗号分隔
      if (this.multi && this.multiValueType == 'string') {
        value = value ? value.join(',') : '';
      }
      this.$emit('input', value);
      const option = this.thisOptions.find(option => option[this.valueFieldName] == value);
      // change 事件, 事件参数: value 选项值, option 选择选项数据, options 所有选项数据
      this.$emit('change', value, option, this.thisOptions);
    },
    /**
     * 查询
     * @param {String} value 查询内容
     */
    filterOption(inputValue, option) {
      const optionData = option.data.attrs.optionData;
      if (!optionData[this.labelFieldName]) {
        return false;
      }
      const labelInclude = optionData[this.labelFieldName].includes(inputValue);
      // const labelInclude = this.thisOptions.includes(inputValue);
      if (labelInclude) {
        return labelInclude;
      }
      // 查询的属性字段名
      if (!this.searchKeys.length) {
        return false;
      }
      for (let i = 0; i < this.searchKeys.length; i++) {
        const key = this.searchKeys[i];
        const value = optionData[key];
        const include = value ? (value + "").includes(inputValue) : false;
        if (include) {
          return true;
        }
      }
      return false;
    },
    /**
     * 检查给的选项中是否有选中的数据, 没有则清空选择的值
     * @param {Array | null | undefined} options 选项数据
     */
    changeOptions(options) {
      if (!options || !options.length) {
        this.thisOptions = [];
        this.change(null);
        return;
      }
      this.thisOptions = options;
      // 检查新给的选项中, 是否包含 已选择的 value 项
      // 单选情况, 如果不包含, 则清空选择的 value 值
      if (!this.multi && !options.find(option => option[this.valueFieldName] == this.thisValue)) {
        this.change(null);
        return;
      }
      // 多选情况, 移除新选项集合中, 不包含的值
      if (this.multi && this.thisValue && this.thisValue.length) {
        this.change(
          options
            .filter(option => this.thisValue.includes(option[this.valueFieldName]))
            .map(option => option[this.valueFieldName])
        );
        return;
      }
    }
  },
  computed: {
    // 显示标签字段名, 默认 "name"
    labelFieldName() {
      return this.nameMapping ? this.nameMapping : 'name';
    },
    // 值得字段名, 默认 "id"
    valueFieldName() {
      return this.valueMapping ? this.valueMapping : 'id';
    },
    // 本地值
    thisValue() {
      // null 多选会显示一个 空的标签, 这里返回 undefined
      if (this.value == null) {
        return undefined;
      }
      if (this.multi && this.multiValueType == 'string') {
        return this.value ? this.value.split(',') : [];
      } else {
        return this.value;
      }
    },
  },
  watch: {
    /**
     * 上层选项改变, 重新设置本地 options
     */
    option(newValue) {
      this.changeOptions(newValue);
    },
    /**
     * 上层api地址改变, 重新设置本地 options
     */
    api(newValue) {
      // 新 api 不存在, 将本地选项数据和选择的数据置空
      if (!newValue) {
        this.changeOptions();
        return;
      }
      // 新 api 存在, 请求数据
      request({
        url: this.api,
        method: 'get',
      }).then(r => this.changeOptions(r.data));
    }
  }
}
</script>

<style lang='less' scoped></style>