import request from '@/utils/request'

// 查询订单-组局信息-裂变列表
export function listGroupFission(query) {
  return request({
    url: '/order/group/fission/list',
    method: 'get',
    params: query
  })
}

// 查询订单-组局信息-裂变详细
export function getGroupFission(id) {
  return request({
    url: '/order/group/fission/' + id,
    method: 'get'
  })
}

// 新增订单-组局信息-裂变
export function addGroupFission(data) {
  return request({
    url: '/order/group/fission',
    method: 'post',
    data: data
  })
}

// 修改订单-组局信息-裂变
export function updateGroupFission(data) {
  return request({
    url: '/order/group/fission',
    method: 'put',
    data: data
  })
}
export function fission(data) {
  return request({
    url: '/order/group/fission/fission',
    method: 'put',
    data: data
  })
}
// 删除订单-组局信息-裂变
export function delGroupFission(id) {
  return request({
    url: '/order/group/fission/' + id,
    method: 'delete'
  })
}
