<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <!--            <a-col :md="8" :sm="24">
              <a-form-item label="店铺id" prop="shopId">
                <a-input v-model="queryParam.shopId" placeholder="请输入店铺id" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="剧本id" prop="scriptId">
                <a-input v-model="queryParam.scriptId" placeholder="请输入剧本id" allow-clear/>
              </a-form-item>
            </a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item label="店铺名称" prop="shopName">
                <a-input v-model="queryParam.shopName" placeholder="请输入店铺名称" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="剧本名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入剧本名称" allow-clear />
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">
              <a-form-item label="组局来源" prop="typeAdmin">
                <api-select v-model="queryParam.typeAdmin" valueMapping="id" placeholder="请选择组局来源" :option="[
                  { id: 0, name: 'APP' },
                  { id: 1, name: '平台' }
                ]" />
              </a-form-item>
            </a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item label="组局来源" prop="typeAdmin">
                <a-select placeholder="请选择组局来源" style="width:100%" v-model="queryParam.typeAdmin" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.OrderGroupTypeAdminEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="组团状态" prop="groupStatus">
                <a-select placeholder="请选择组团状态" style="width:100%" v-model="queryParam.groupStatus" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.OrderGroupStatusEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="是否分裂组局" prop="isFission">-->
<!--                <a-select placeholder="请选择是否锁车" style="width: 100%" v-model="queryParam.isFission" allowClear>-->
<!--                  <a-select-option v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">-->
<!--                    {{ item.name }}-->
<!--                  </a-select-option>-->
<!--                </a-select>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <template v-if="advanced">
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="玩本时间(年月日)" prop="playTime">-->
<!--                  <a-date-picker style="width: 100%" @change="onDateChange" :showTime="true" format="YYYY-MM-DD HH:mm:ss"-->
<!--                    allow-clear />-->
<!--                </a-form-item>-->
<!--              </a-col>-->
              <!--              <a-col :md="8" :sm="24">
                <a-form-item label="所在城市" prop="city">
                  <a-input v-model="queryParam.city" placeholder="请输入所在城市" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="价格" prop="personPrice">
                  <a-input v-model="queryParam.personPrice" placeholder="请输入价格" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="简介" prop="introduction">
                  <a-input v-model="queryParam.introduction" placeholder="请输入简介" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否允许角色反转 1:是,0:否" prop="isAllowReverse">
                  <a-input v-model="queryParam.isAllowReverse" placeholder="请输入是否允许角色反转 1:是,0:否" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="店里男玩家占位" prop="humanFreeNum">
                  <a-input v-model="queryParam.humanFreeNum" placeholder="请输入店里男玩家占位" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="店里女玩家占位" prop="womanFreeNum">
                  <a-input v-model="queryParam.womanFreeNum" placeholder="请输入店里女玩家占位" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="男生人数" prop="humanNum">
                  <a-input v-model="queryParam.humanNum" placeholder="请输入男生人数" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="女生人数" prop="womanNum">
                  <a-input v-model="queryParam.womanNum" placeholder="请输入女生人数" allow-clear/>
                </a-form-item>
              </a-col>-->
              <a-col :md="8" :sm="24">
                <a-form-item label="是否锁车" prop="isLock">
                  <a-select placeholder="请选择是否锁车" style="width: 100%" v-model="queryParam.isLock" allowClear>
                    <a-select-option value="true">是</a-select-option>
                    <a-select-option value="false">否</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <!--              <a-col :md="8" :sm="24">
                <a-form-item label="人气值" prop="popularValue">
                  <a-input v-model="queryParam.popularValue" placeholder="请输入人气值" allow-clear/>
                </a-form-item>
              </a-col>-->
              <a-col :md="8" :sm="24">
                <a-form-item label="联系人电话" prop="mobile">
                  <a-input v-model="queryParam.mobile" placeholder="请输入联系人电话" allow-clear />
                </a-form-item>
              </a-col>

            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['order:group:add']">
          <a-icon type="plus" />新增
        </a-button>
        <!--<a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['order:group:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['order:group:remove']">
          <a-icon type="delete" />删除
        </a-button>-->
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['order:group:export']">
          <a-icon type="download" />导出
        </a-button>-->
        <table-setting :style="{ float: 'right' }" :table-size.sync="tableSize" v-model="columns"
          :refresh-loading="loading" @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <create-fission-form ref="CreateFissionForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false"
        :bordered="tableBordered">
        <span slot="playTime" slot-scope="text, record">
          {{ parseTime(record.playTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['order:group:edit']" />-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['order:group:edit']">-->
<!--            <a-icon type="edit" />修改-->
<!--          </a>-->
          <a @click="$refs.CreateFissionForm.handleUpdate(record, undefined)" v-hasPermi="['order:group:edit']">
            <a-icon type="edit" />组局裂变
          </a>
          <a-divider type="vertical" v-hasPermi="['order:group:remove']" />
<!--          <a @click="handleDelete(record)" v-hasPermi="['order:group:remove']">-->
<!--            <a-icon type="delete" />删除-->
<!--          </a>-->
        </span>
        <span slot="groupStatus" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（标签）-->
          <custom-dict-tag :options="customDict.OrderGroupStatusEnum" :value="record.groupStatus" />
        </span>
        <span slot="isLock" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（下架）-->
          <custom-dict-tag :options="customDict.TFEnum" :value="record.isLock" />
        </span>
        <span slot="playTime" slot-scope="text, record">
          {{ parseTime(record.playTime) }}
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
        :total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange" @change="changeSize" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listGroup, delGroup } from '@/api/order/group'
import CreateForm from './modules/CreateForm'
import CreateFissionForm from './modules/CreateFissionForm'
import { tableMixin } from '@/store/table-mixin'
import { mapGetters } from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import ApiSelect from '@/views/config/badge/modules/ApiSelect.vue';

export default {
  name: 'Group',
  components: {
    CreateForm,
    CreateFissionForm,
    CustomDictTag,
    ApiSelect
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        shopId: null,
        scriptId: null,
        playTime: null,
        city: null,
        personPrice: null,
        introduction: null,
        isAllowReverse: null,
        humanFreeNum: null,
        womanFreeNum: null,
        humanNum: null,
        womanNum: null,
        groupStatus: null,
        isLock: null,
        isFission: null,
        popularValue: null,
        mobile: null,
        shopName: null,
        name: null,
        pageNum: 1,
        pageSize: 10,
        typeAdmin: null
      },
      columns: [
        /* {
           title: 'id',
           dataIndex: 'id',
           ellipsis: true,
           align: 'center'
         },*/
        /*{
          title: '店铺id shop_shop.id',
          dataIndex: 'shopId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '剧本id script_script.id',
          dataIndex: 'scriptId',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '店铺名称',
          dataIndex: 'shopName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '剧本名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '玩本时间',
          dataIndex: 'playTime',
          scopedSlots: { customRender: 'playTime' },
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '所在城市',
          dataIndex: 'city',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '价格',
          dataIndex: 'personPrice',
          ellipsis: true,
          align: 'center'
        },
        /* {
           title: '简介',
           dataIndex: 'introduction',
           ellipsis: true,
           align: 'center'
         },
         {
           title: '是否允许角色反转 1:是,0:否',
           dataIndex: 'isAllowReverse',
           ellipsis: true,
           align: 'center'
         },*/
        {
          title: '男玩家占位',
          dataIndex: 'humanFreeNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '女玩家占位',
          dataIndex: 'womanFreeNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '男生人数',
          dataIndex: 'humanNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '女生人数',
          dataIndex: 'womanNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '组团状态',
          dataIndex: 'groupStatus',
          ellipsis: true,
          scopedSlots: { customRender: 'groupStatus' },
          align: 'center'
        },
        {
          title: '是否锁车',
          dataIndex: 'isLock',
          scopedSlots: { customRender: 'isLock' },
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '人气值',
          dataIndex: 'popularValue',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '联系人电话',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created() {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    onDateChange(date, dateString) {
      // console.log(date, dateString)
      this.queryParam.playTime = dateString
    },
    /** 查询订单-组局信息列表 */
    getList() {
      // console.log(this.queryParam.playTime)
      this.loading = true
      listGroup(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        shopId: undefined,
        scriptId: undefined,
        playTime: undefined,
        city: undefined,
        personPrice: undefined,
        introduction: undefined,
        isAllowReverse: undefined,
        humanFreeNum: undefined,
        womanFreeNum: undefined,
        humanNum: undefined,
        womanNum: undefined,
        groupStatus: undefined,
        isLock: undefined,
        isFission:undefined,
        popularValue: undefined,
        mobile: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delGroup(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() { }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('order/group/export', {
            ...that.queryParam
          }, `group_${new Date().getTime()}.xlsx`)
        },
        onCancel() { }
      })
    }
  }
}
</script>
