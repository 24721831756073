<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="店铺" prop="shopId">
        <api-select v-model="form.shopId" api="/shop/shop/select-list" nameMapping="shopName" @change="shopChange"
          :searchKeys="['mobile', 'address']">
          <template v-slot="{ option }">
            <div class="shop-select-option-container">
              <a-avatar class="shop-select-option-avatar" :size="32" :src="option.logo" icon="file-image" />
              <div>
                <div>{{ option.shopName }} <span>{{ option.mobile }}</span></div>
                <div style="font-size:12px">{{ option.address }}</div>
              </div>
            </div>
          </template>
        </api-select>
      </a-form-model-item>
      <a-form-model-item label="剧本" prop="scriptId">
        <api-select v-model="form.scriptId" :api="scriptSelectApiUrl" @change="scriptChange" valueMapping="scriptId"
          :searchKeys="['humanNum', 'womanNum']">
          <template v-slot="{ option }">
            <div class="shop-select-option-container">
              <a-avatar class="shop-select-option-avatar" :size="32" :src="option.cover" icon="file-image" />
              <div>
                <span style="font-size: 12px">
                  <a-tag v-if="option.isTreasure">镇店</a-tag>
                  <a-tag v-if="option.isNew" color="green">新</a-tag>
                  <a-tag v-if="option.isHot" color="red">热</a-tag>
                  <a-tag v-if="option.humanNum">{{ option.humanNum }}男</a-tag>
                  <a-tag v-if="option.womanNum">{{ option.womanNum }}女</a-tag>
                </span>
                {{ option.name }}
              </div>
            </div>
          </template>
        </api-select>

      </a-form-model-item>
      <a-form-model-item label="玩本时间" prop="playTime">
        <date-picker-pack v-model="form.playTime" @change="calcPrice"></date-picker-pack>
      </a-form-model-item>
      <a-form-model-item label="组局价格" prop="personPrice">
        <div style="margin-left: 10px;font-size: 20px"> {{ form.personPrice == null ? '-' : form.personPrice }}/人</div>
      </a-form-model-item>

      <a-form-model-item label="角色反串" prop="isAllowReverse">
        <a-radio-group v-model="form.isAllowReverse" :options="[
          { value: 1, label: '允许' },
          { value: 0, label: '不允许' }
        ]" />
      </a-form-model-item>
      <a-form-model-item label="店里玩家">
        <div>
          剧本玩家数量: {{ scriptPeopleCount.man }} 男 {{ scriptPeopleCount.woman }} 女
        </div>
        <span>
          <a-input-number v-model="form.humanFreeNum" :min="0" :max="maxHumanFreeNum" /> 男
        </span>
        <span style="margin-left: 20px">
          <a-input-number v-model="form.womanFreeNum" :min="0" :max="maxWomanFreeNum" /> 女
        </span>
      </a-form-model-item>

      <a-form-model-item label="组局简介" prop="introduction">
        <a-textarea v-model="form.introduction" placeholder="请输入组局简介" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getPrice, addGroup, updateGroup } from '@/api/order/group'
import ApiSelect from '@/views/config/badge/modules/ApiSelect.vue'

import DatePickerPack from "./DatePickerPack.vue";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    ApiSelect, DatePickerPack
  },
  computed: {
    // 最大男生数量
    maxHumanFreeNum() {
      // 允许反串, 则 最大男生数量 = 剧本男生数量 + 剧本女生数量 - 店里女玩家数量
      if (this.form.isAllowReverse) {
        return this.scriptPeopleCount.man + this.scriptPeopleCount.woman - this.form.womanFreeNum;
      }
      return this.scriptPeopleCount.man;
    },
    // 最大女生数量
    maxWomanFreeNum() {
      // 允许反串, 则 最大女生数量 = 剧本男生数量 + 剧本女生数量 - 店里男玩家数量
      if (this.form.isAllowReverse) {
        return this.scriptPeopleCount.man + this.scriptPeopleCount.woman - this.form.humanFreeNum;
      }
      return this.scriptPeopleCount.woman;
    }
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        shopId: null,
        scriptId: null,
        playTime: null,
        personPrice: null,
        introduction: null,
        isAllowReverse: 1,
        humanFreeNum: 0,
        womanFreeNum: 0,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        shopId: [
          { required: true, message: '请选择店铺', trigger: 'blur' }
        ],
        scriptId: [
          { required: true, message: '请选择剧本', trigger: 'blur' }
        ],
        playTime: [
          { required: true, message: '请选择玩本时间', trigger: 'blur' }
        ],
        personPrice: [
          { required: true, message: '价格不能为空', trigger: 'blur' }
        ],
        isAllowReverse: [
          { required: true, message: '请选择是否允许角色反转', trigger: 'blur' }
        ],
      },
      // 剧本下拉框 api 地址
      scriptSelectApiUrl: null,
      // 剧本人数上限
      scriptPeopleCount: {
        man: 0,
        woman: 0
      }
    }
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        shopId: null,
        scriptId: null,
        playTime: null,
        personPrice: null,
        introduction: null,
        isAllowReverse: 1,
        humanFreeNum: 0,
        womanFreeNum: 0,
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateGroup(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addGroup(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    /**
     * 店铺下拉框改变
     * @param {String} value 改变后的值, shopId
     */
    shopChange(value) {
      this.scriptSelectApiUrl = value ? '/shop/shop/script/select-list?shopId=' + value : null;
    },
    /**
     * 剧本下拉框改变
     * @param {String} seciptId 剧本id
     * @param {Object} selectOption 选中的选项
     * @param {Array} allOption  下拉框的所有选项
     */
    scriptChange(seciptId, selectOption, allOption) {
      this.calcPrice();
      // 未选中, 不设置上限
      if (!selectOption) {
        this.scriptPeopleCount.man = 0;
        this.scriptPeopleCount.woman = 0;
        this.form.humanFreeNum = 0;
        this.form.womanFreeNum = 0;
        return;
      }
      // 剧本人数上限
      this.scriptPeopleCount.man = selectOption.humanNum;
      this.scriptPeopleCount.woman = selectOption.womanNum;
    },
    /**
     * 计算价格
     */
    calcPrice() {
      const fieldName = ['shopId', 'scriptId', 'playTime'];
      for (let i = 0; i < fieldName.length; i++) {
        const field = fieldName[i];
        if (!this.form[field]) {
          this.form.personPrice = null;
          return;
        }
      }

      const params = {
        shopId: this.form.shopId,
        scriptId: this.form.scriptId,
        playTime: this.form.playTime,
      };
      getPrice(params).then(r => {
        if (!r.success) {
          this.$message.warn('价格计算失败');
          this.form.personPrice = null;
          return;
        }
        this.form.personPrice = r.data;
      })
    }
  },


}

</script>
<style>
.shop-select-option-container {
  display: flex;
  align-items: center;
}

.shop-select-option-avatar {
  margin-right: 10px;
}
</style>